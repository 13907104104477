<template>
	<section>
		<v-skeleton-loader :loading="loading" :type="types">
			<ArticleFrame
				v-for="item in items"
				:key="'tagArticle_' + item.sk"
				:item="item"
				@loadingChk="more"
			/>
			<ProgressCircle :remainFlag="remainFlag" />
		</v-skeleton-loader>
	</section>
</template>

<script>
import _ from "lodash";
import ArticleFrame from "@/components/article/ArticleFrame.vue";
import ProgressCircle from "@/components/article/ProgressCircle.vue";

export default {
	name: "Tag",
	data() {
		return {
			index: 0,
			items: [],
			loading: true,
			remainFlag: true,
			moreLoading: false,
			types: "list-item-avatar-two-line, image, paragraph, actions, list-item-avatar-two-line, image, paragraph, actions",
		};
	},
	components: {
		ArticleFrame,
		ProgressCircle,
	},
	computed: {
		unit() {
			return Array.from(
				{
					length:
						this.itemsChunk.length < 4 ? this.itemsChunk.length : 4,
				},
				(v, i) => i
			);
		},
		itemsChunk() {
			return this.$store.state.tags.items || [];
		},
		lastEvaluatedKey() {
			return this.$store.state.tags.lastEvaluatedKey;
		},
	},
	methods: {
		async more(sk) {
			if (this.itemsChunk.length > this.index) {
				if (sk == this.itemsChunk[this.index - 1].sk) {
					let count = this.itemsChunk.length - this.index;
					let subUnit = Array.from(
						{
							length: count > 4 ? 4 : count,
						},
						(v, i) => i
					);
					subUnit.forEach(() => {
						this.items = _.concat(
							this.items,
							this.itemsChunk[this.index++]
						);
					});
				}
			} else {
				if (this.lastEvaluatedKey) {
					this.remainFlag = true;
					if (this.moreLoading) {
						return;
					}
					this.moreLoading = true;
					// await this.$store.dispatch("FETCH_MORE_ITEMS");
					this.items = _.concat(
						this.items,
						this.itemsChunk[this.index++]
					);
					if (!this.lastEvaluatedKey) {
						this.remainFlag = false;
					}
					this.moreLoading = false;
				} else {
					this.remainFlag = false;
				}
			}
		},
	},
	async created() {
		this.$store.state.tags.items = [];
		await this.$store.dispatch("TAG_ITEMS", this.$route.params.tag);
		this.unit.forEach(() => {
			this.items.push(this.itemsChunk[this.index++]);
		});
	},
	mounted() {
		const loadingInterval = setInterval(() => {
			if (this.itemsChunk.length > 0) {
				clearInterval(loadingInterval);
				this.loading = false;
			}
		}, 100);
		setTimeout(() => {
			clearInterval(loadingInterval);
			this.loading = false;
			this.remainFlag = false;
		}, 5000);
	},
};
</script>